header {
    width: 100%;
    background-color: $tertiaryColor;
    display: flex;
    justify-content: space-between;

    .about {

        button {
            margin: 5px 5px 5px 15px;
            padding: 5px 10px;
            box-sizing: border-box;
            background-color: #fff;
            border: 0;
            transition: $transition;

            &:hover {
                background-color: rgb(228, 228, 228)
            }
        }
    }

    .log {

        button {
            margin: 5px 15px 5px 5px;
            padding: 5px 10px;
            box-sizing: border-box;
            background-color: #fff;
            border: 0;
            transition: $transition;

            &:hover {
                background-color: rgb(228, 228, 228)
            }
        }
    }
}