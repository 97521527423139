body {
    margin: 0;
    padding: 0;
    background-image: url("../img/background.jpg");
    background-size: cover;
    background-attachment: fixed;
    color: $primaryColor;
    box-sizing: border-box;
    font-family: $primaryFont;
    min-height: 100vh;
    position: relative
}

#legal,
#privacy  {
    background-color: #fff;
    padding: 25px;
    box-sizing: border-box
}