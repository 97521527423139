main {
    width: 100%;
    max-width: $desktop;
    margin: 0 auto;
    padding: $padding;
    box-sizing: border-box;
    text-align: center;

    #logo {
        width: 400px;
    }

    #graph {
        position: absolute;
        width: 100vw;
        left: 0;
        bottom: 50px;
        box-sizing: border-box;
    }

    .player {
        color: #000;
        font-weight: bold;
        position: absolute;
        bottom: 71px;
        left: 35vw;
        z-index: 10;

        #more {
                margin: 5px 5px 5px 15px;
                padding: 5px 10px;
                box-sizing: border-box;
                background-color: #fff;
                border: 0;
                transition: $transition;
    
                &:hover {
                    background-color: rgb(228, 228, 228)
                }
        }
    }
}